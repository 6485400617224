import clsx from 'clsx';

type IconProps ={
  name: string;
  type?: IconType;
  className?: string;
}

export enum IconType {
  Stroke = 'stroke',
  Solid = 'solid',
  Duotone = 'duotone',
  Twotone = 'twotone',
  Bulk = 'bulk',
}

/**
 * @param param0 
 * @returns 
 */
export const Icon = ( { name, type = IconType.Stroke, className }: IconProps ) => {

  return (
    <i className={ clsx(
      `hgi-${type} hgi-${name} flex`,
      className
    ) } />
  )
}
